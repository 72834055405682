import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { fetchCustomVideoMetadata, fetchVimeoData, fetchYoutubeData, formatDuration, getCustomUrl, getCustomVideoDuration, getVimeoId, getYoutubeId } from '../../utils/validateVideo';
import { Helmet } from 'react-helmet';

export const ContentfulVideo = ({ videoData }) => {
  const [duration, setDuration] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const [videoId, setVideo] = useState();
  const [channel, setChannel] = useState("");
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const videoRef = useRef(null);

  // Detect when video is in viewport
  const handleIntersection = useCallback((entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIsVideoVisible(true);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "200px",
      threshold: 0.1,
    });
    if (videoRef.current) observer.observe(videoRef.current);
    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, [handleIntersection]);
  
  useEffect(() => {
    if (videoData?.videoUrl) {
      if (videoData?.videoUrl?.includes("youtu.be")) {
        const youtubeId = getYoutubeId(videoData?.videoUrl)
        setChannel("youtube")
        setVideo(youtubeId)
      }
      if (videoData?.videoUrl.includes("vimeo")) {
        const vimeoId = getVimeoId(videoData?.videoUrl)
        setChannel("vimeo")
        setVideo(vimeoId)
      }
    } else if (videoData?.videoFile) {
      const customId = getCustomUrl(videoData?.videoFile)
      setChannel("custom")
      setVideo(customId)
    }
  }, [videoData]);

  useEffect(() => {
    const fetchVideoMetadata = async () => {
      if (channel === "youtube") {
        const videoData = await fetchYoutubeData(videoId);
        setDuration(formatDuration(videoData.duration));
        setUploadDate(videoData.uploadDate);
      } else if (channel === "vimeo") {
        const videoData = await fetchVimeoData(videoId);
        setDuration(formatDuration(videoData.duration));
        setUploadDate(videoData.uploadDate);
      } else if (channel === "custom") {
        const videoDuration = await getCustomVideoDuration(videoId);
        const uploadDate = await fetchCustomVideoMetadata(videoId);
        setDuration(formatDuration(videoDuration));
        setUploadDate(uploadDate || "2025-01-01T12:00:00Z");
      }
    };

    if (videoId) fetchVideoMetadata();
  }, [videoId, channel])

  const schema = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "embedUrl": videoData?.videoFile?.file?.url ? 'https:' + videoData?.videoFile?.file?.url : videoData?.videoUrl,
    "thumbnailUrl": videoData?.videoThumbnail?.gatsbyImageData?.images?.sources[0]?.srcSet || videoData?.videoThumbnail?.file?.url || 'Video Thumbnail',
    "name": videoData?.title || 'Video Title',
    "description": videoData?.caption?.raw || 'Video Description',
    "duration": duration,
    "uploadDate": uploadDate || "2025-01-01T12:00:00Z",
  };
  
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      </Helmet>
      <VideoCompWrapper
        data-comp={ContentfulVideo.displayName}
        ref={videoRef}
      >
        {isVideoVisible && (
          <ReactPlayer
            url={videoData?.videoFile?.file?.url ? 'https:' + videoData?.videoFile?.file?.url : videoData?.videoUrl}
            playing={videoData?.autoplay}
            controls={true}
            loading="lazy"
          />
        )}
        {videoData?.caption?.raw && <Caption><div style={{ textAlign: videoData?.alignCaption === 'center' ? 'center' : 'left', paddingTop: '16px' }}>{documentToReactComponents(JSON.parse(videoData?.caption?.raw))}</div></Caption>}
      </VideoCompWrapper>
    </>
  );
};

ContentfulVideo.displayName = 'ContentfulVideo';

const VideoCompWrapper = styled.div`
  color: black;
`

const Caption = styled.p`
  padding-bottom: 16px;
  font-size: 12px;
  font-family: Roboto, 'Roboto', sans-serif; 
  color: #1B1B1B;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
`